// src/App.js or src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Screens/Home'; // Assume you have a Home component
import Map from './Screens/Map'; // This will be your new Map view
import { MapProvider } from './contexts/MapContext';
import { UserProvider } from './contexts/UserContext';
import { ImagesProvider } from './contexts/ImagesContext';

function App() {
  return (
    <MapProvider>
      <UserProvider>
        <ImagesProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/map" element={<Map />} />
            </Routes>
          </Router>
        </ImagesProvider>
      </UserProvider>
    </MapProvider>
  );
}

export default App;
