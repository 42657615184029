// Header.js
import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, TextField, Box } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import { baseurl } from '../config';
import logo from '../edil-logo.png';

function Header(props) {
  const { user, setUser, isLoggedIn, setIsLoggedIn } = useContext(UserContext);
  const [inputUsername, setInputUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    axios.post(baseurl + './api/login/', { username: inputUsername, password })
      .then(response => {
        if (response.status === 200) {
          setUser(inputUsername);
          setIsLoggedIn(true);
          setError('');
        }
      })
      .catch(error => {
        console.error('Login error:', error);
        setError('Could not login');
      });
  };

  const handleLogout = () => {
    setUser(null);
    setIsLoggedIn(false);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box component="img" src={logo} alt="Logo" sx={{ height: 40, mr: 2 }} />
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          FWA Photo Manager
        </Typography>
        {isLoggedIn ? (
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
            <AccountCircle />
            <Typography variant="h6" sx={{ ml: 1 }}>
              {user}
            </Typography>
            <Button color="inherit" onClick={handleLogout} sx={{ ml: 2 }}>Logout</Button>
          </Box>
        ) : (
          <Box component="form" sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
            <TextField
              label="Username"
              value={inputUsername}
              onChange={(e) => setInputUsername(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ mr: 1, backgroundColor: 'white' }}
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ mr: 1, backgroundColor: 'white' }}
            />
            <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
            {error && (
              <Typography variant="body2" color="error" sx={{ ml: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
