// App.js
import React, { useState } from 'react';
import { Container, Grid, Box } from '@mui/material';
import Header from '../components/Header';
import Map from '../components/Map';
import SidePanel from '../components/SidePanel';
import { ImagesProvider } from '../contexts/ImagesContext';
import { UserProvider } from '../contexts/UserContext';
import { MapProvider } from '../contexts/MapContext';

function Home() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header />
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={9} sx={{ height: '100%' }}>
                        <Map />
                    </Grid>
                    <Grid item xs={3} sx={{ height: '100%' }}>
                        <SidePanel />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Home;
