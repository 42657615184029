export function generateNextUrl(baseUrl, params) {
    if (!baseUrl)
        return null;
    const url = new URL(baseUrl);
    Object.keys(params).forEach(key => {
        if (params[key]) {
            if (typeof params[key] === 'object' && params[key] !== null) {
                Object.keys(params[key]).forEach(subKey => {
                    url.searchParams.append(`${key}[${subKey}]`, params[key][subKey]);
                });
            } else {
                url.searchParams.append(key, params[key]);
            }
        }
    });
    return url.toString();
}
