import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ImagesContext } from '../contexts/ImagesContext';
import { baseurl } from '../config';
import axios from 'axios';

const ImageModal = ({ open, onClose, imageId }) => {
    const { images } = useContext(ImagesContext);
    const [currentImageUrl, setCurrentImageUrl] = useState();
    const [currentImageId, setCurrentImageId] = useState(imageId);
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        setCurrentImageUrl(null);
        setCurrentImageId(imageId);
    }, [imageId])

    useEffect(() => {
        if (currentImageId) {
            console.log(images.find(i => i.id === currentImageId))
            let currentImage = images.find(i => i.id === currentImageId);
            if (images.some(i => i.id === currentImageId)) {
                currentImage = images.find(item => item.id === currentImageId);
                setCurrentImageUrl(baseurl + currentImage.local_path)
            } else {
                axios.get(baseurl + `./api/image/${currentImageId}/`).then(response => {
                    if (response.status === 200) {
                        currentImage = response.data;
                        setCurrentImageUrl(baseurl + currentImage.local_path)
                    }
                }).catch(error => {
                    console.log(error);
                    setCurrentImageUrl(null);
                });
            }
        }
    }, [images, currentImageId])

    const handleRotateLeft = () => {
        setRotation((prevRotation) => prevRotation - 90);
    };

    const handleRotateRight = () => {
        setRotation((prevRotation) => prevRotation + 90);
    };

    const handleNextImage = () => {
        setRotation(0); // Reset rotation when changing images
        const currentIndex = images.findIndex(image => image.id === currentImageId);
        const nextIndex = (currentIndex + 1) % images.length;
        setCurrentImageId(images[nextIndex]?.id);
    };

    const handlePrevImage = () => {
        setRotation(0); // Reset rotation when changing images
        const currentIndex = images.findIndex(image => image.id === currentImageId);
        const prevIndex = (currentIndex - 1 + images.length) % images.length;
        setCurrentImageId(images[prevIndex]?.id);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    src={currentImageUrl}
                    alt="Full Image"
                    style={{
                        maxWidth: '100%',
                        height: '80vh',
                        objectFit: 'contain',
                        transform: `rotate(${rotation}deg)`
                    }}
                />
            </DialogContent>
            <DialogActions>
                <IconButton onClick={handlePrevImage} color="primary">
                    <ArrowBackIcon />
                </IconButton>
                <IconButton onClick={handleRotateLeft} color="primary">
                    <RotateLeftIcon />
                </IconButton>
                <IconButton onClick={handleRotateRight} color="primary">
                    <RotateRightIcon />
                </IconButton>
                <IconButton onClick={handleNextImage} color="primary">
                    <ArrowForwardIcon />
                </IconButton>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageModal;
