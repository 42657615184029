import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Grid, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Thumbnails from './Thumbnails';
import { ImagesContext } from '../contexts/ImagesContext';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import { baseurl } from '../config';

function SidePanel() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [username, setUsername] = useState('');
  const [area, setArea] = useState('');
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [sideSearch, setSideSearch] = useState(false);
  const { searchImages, clearSearch, searchMode, searchParams, setSearchParams, setSelectedIds } = useContext(ImagesContext);
  const { isLoggedIn } = useContext(UserContext);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(baseurl + './api/users/');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get(baseurl + './api/areas/');
      setAreas(response.data);
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchAreas();
  }, []);

  const handleSearch = () => {
    const requestData = {
      startDate,
      endDate,
      username,
      area,
      boundingBox: searchMode ? searchParams.boundingBox : null,
    };

    setSearchParams(requestData);
  };

  const handleClear = () => {
    setStartDate('');
    setEndDate('');
    setUsername('');
    setArea('');
    clearSearch();
  };

  useEffect(() => {
    if (searchMode) {
      console.log(searchParams)
      handleSearch();
      toggleSideSearch();
    }
  }, [searchMode, startDate, endDate, username, area]);

  const toggleSideSearch = () => {
    setSideSearch(prev => !prev);
  };

  useEffect(() => {
    if (sideSearch) {
      searchImages();
      setSelectedIds([])
    }
    setSideSearch(false);
  }, [sideSearch]);

  if (!isLoggedIn) {
    return <></>
  }

  return (
    <div style={{ paddingTop: 15, paddingRight: 10, height: 'calc(100vh - 79px)' }}>
      <form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleSearch(); toggleSideSearch(); }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => option.fullname || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
                value={users.find(user => user.username === username) ?? ''}
                onChange={(e, newValue) => {
                  setUsername(newValue ? newValue.username : '');
                }}
                freeSolo
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <Autocomplete
                options={areas}
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Area"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
                value={areas.find(ar => ar.id === area) ?? ''}
                onChange={(e, newValue) => {
                  setArea(newValue ? newValue.id : '');
                }}
                freeSolo
              />
            </FormControl>
          </Grid>
          <Grid item container spacing={2}>
            {!searchMode && (
              <Grid item xs={6}>
                <Button variant="contained" color="primary" fullWidth type="submit">
                  Search
                </Button>
              </Grid>
            )}
            <Grid item xs={searchMode ? 12 : 6}>
              <Button variant="outlined" color="secondary" fullWidth onClick={handleClear}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <div>
        <Thumbnails />
      </div>
    </div>
  );
}

export default SidePanel;
