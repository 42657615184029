import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {
  Grid,
  Paper,
  Menu,
  MenuItem,
  Box,
  IconButton,
  CircularProgress,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import MapIcon from '@mui/icons-material/Map';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import { ImagesContext } from '../contexts/ImagesContext';
import { MapContext } from '../contexts/MapContext';
import { fromLonLat, toLonLat } from 'ol/proj';
import { baseurl } from '../config';
import ImageModal from './ImageModal';

function Thumbnails() {
  const { mapRef } = useContext(MapContext)
  const { images, imagesLoading, loadMoreImages, nextPageUrl, downloadImages, deleteImages, selectedIds, setSelectedIds } = useContext(ImagesContext);
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuInfo, setContextMenuInfo] = useState({});
  const [currentThumbId, setCurrentThumbId] = useState(null);
  const [visibleImages, setVisibleImages] = useState([]);
  const [loading, setLoading] = useState(false); // Track loading state
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Track confirmation dialog state
  const [deleteTargetIds, setDeleteTargetIds] = useState([]); // Track ids to delete
  const [openImageModal, setOpenImageModal] = useState(false);
  const [modalImageId, setModalImageId] = useState('');
  const observer = useRef();


  const handleLoadMoreImages = useCallback(async () => {
    if (nextPageUrl) {
      setLoading(true); // Start loading
      try {
        await loadMoreImages();
      } catch (error) {
        console.error('Error loading more images:', error);
      }
      setLoading(false); // Stop loading 
    }
  }, [loadMoreImages, nextPageUrl]);

  useEffect(() => {
    console.log(imagesLoading)
    setVisibleImages(images);
    //setSelectedIds([]);
  }, [images, imagesLoading]);

  const lastImageRef = useCallback(
    (node) => {
      console.log(nextPageUrl)
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPageUrl) {
          handleLoadMoreImages();
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextPageUrl]
  );

  const handleThumbnailClick = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      setSelectedIds((prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((selectedId) => selectedId !== id)
          : [...prevSelected, id]
      );
    } else if (e.shiftKey) {
      const lastSelectedId = selectedIds[selectedIds.length - 1];
      const lastSelectedIndex = images.findIndex((thumb) => thumb.id === lastSelectedId);
      const currentIndex = images.findIndex((thumb) => thumb.id === id);
      const [start, end] = currentIndex < lastSelectedIndex ? [currentIndex, lastSelectedIndex] : [lastSelectedIndex, currentIndex];
      const rangeIds = images.slice(start, end + 1).map((thumb) => thumb.id);

      setSelectedIds((prevSelected) => Array.from(new Set([...prevSelected, ...rangeIds])));
    } else {
      setSelectedIds([id]);
    }
  };

  const handleThumbnailDoubleClick = (id) => {
    console.log(id)
    setModalImageId(id);
    setOpenImageModal(true);
  };

  const handleContextMenu = (event, info, id, thumb) => {
    event.preventDefault();
    const rect = thumb.getBoundingClientRect();
    setCurrentThumbId(id);
    setContextMenuInfo(info);
    setContextMenu({
      mouseX: rect.left + rect.width / 2,
      mouseY: rect.bottom + window.scrollY,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
    setCurrentThumbId(null);
  };

  const handleDownload = (id) => {
    downloadImages([id]);
    handleClose();
  };

  const handleDelete = (id) => {
    setDeleteTargetIds([id]);
    setConfirmDialogOpen(true);
    handleClose();
  };

  const handleDownloadSelected = () => {
    downloadImages(selectedIds);
  };

  const handleDeleteSelected = () => {
    setDeleteTargetIds(selectedIds);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteImages(deleteTargetIds);
    setConfirmDialogOpen(false);
    setDeleteTargetIds([]);
    setSelectedIds((prevSelected) => prevSelected.filter((id) => !deleteTargetIds.includes(id)));
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setDeleteTargetIds([]);
  };

  const handleStreetView = (geom) => {
    let streetViewURL = "https://maps.gstatic.com/m/streetview/?q=&layer=c&cbll=LAT,LNG&cbp=11,0,0,0,0&output=classic&dg=brw";
    console.log(geom)
    let lng = geom.coordinates[0]
    let lat = geom.coordinates[1]
    streetViewURL = streetViewURL.replace("LAT", lat);
    streetViewURL = streetViewURL.replace("LNG", lng);
    window.open(streetViewURL, '_blank', "");
  }

  const handleGotToImage = (geom, id) => {
    mapRef.current.getView().setCenter(fromLonLat([geom.coordinates[0], geom.coordinates[1]]));
    mapRef.current.getView().setZoom(20);
    setSelectedIds((prevSelected) => Array.from(new Set([...prevSelected, id])));
    handleClose();
  }

  const loadAllImages = async () => {
    let data = {};
    data.ids = images.map(i => i.id);
    data.nexturl = nextPageUrl
    setLoading(true);
    while (data.nexturl) {
      let resp = await loadMoreImages(data.nexturl);
      data.nexturl = resp.nexturl;
      data.ids = [...data.ids, ...resp.ids]
    }
    setLoading(false);
    return data.ids;
  };

  const handleSelectAll = async () => {
    setSelectedIds(await loadAllImages());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const backdropClasses = ['MuiBackdrop-root', 'MuiModal-backdrop'];
      if (contextMenu !== null && backdropClasses.some((cls) => event.target.classList.contains(cls))) {
        event.stopImmediatePropagation();
        event.preventDefault();
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenu]);

  const isSelected = (id) => selectedIds.includes(id);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <>
      <div style={{ marginTop: '20px', marginBottom: '5px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={handleDownloadSelected}
              disabled={selectedIds.length === 0}
              style={{ marginRight: '10px' }}
            >
              Download Selected
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<DeleteIcon />}
              onClick={handleDeleteSelected}
              disabled={selectedIds.length === 0}
            >
              Delete Selected
            </Button>
          </Grid>
          {/* <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<SelectAllIcon />}
              onClick={handleSelectAll}
              disabled={loading}
            >
              Select All
            </Button>
          </Grid> */}
        </Grid>
      </div>
      <div style={{ height: 'calc(100vh - 79px - 430px)', overflowY: 'scroll' }}>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          {(loading || imagesLoading) && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <CircularProgress />
            </Grid>
          )}
          {(visibleImages ?? []).map((thumb, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              key={thumb.id}
              ref={nextPageUrl && index === visibleImages.length - 1 ? lastImageRef : null}
            >
              <Paper
                style={{
                  paddingTop: 4,
                  paddingLeft: 4,
                  paddingRight: 4,
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: isSelected(thumb.id) ? '2px solid blue' : '1px solid gray',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={(e) => handleThumbnailClick(e, thumb.id)}
                onContextMenu={(e) => handleContextMenu(e, thumb, thumb.id, e.currentTarget)}
                onDoubleClick={() => handleThumbnailDoubleClick(thumb.id)}
              >
                <img
                  src={`data:image/jpeg;base64,${thumb.thumbnail}`}
                  alt={`Thumbnail ${thumb.id}`}
                  style={{ width: '100%', height: 'auto', maxHeight: 150, alignSelf: 'center' }}
                />
                {currentThumbId === thumb.id && (
                  <Box
                    style={{
                      position: 'absolute',
                      bottom: 4,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'rgba(255, 255, 255, 0.7)',
                      borderRadius: '4px',
                      padding: '2px 4px',
                    }}
                  >
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Paper>
            </Grid>
          ))}

        </Grid>
      </div>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        PaperProps={{
          style: {
            transform: 'translate(-50%, 10px)',
            marginTop: '10px',
          },
        }}
        className="MuiMenu-root"
      >
        <MenuItem onClick={() => handleGotToImage(JSON.parse(contextMenuInfo.geom_4326, currentThumbId))}>
          <ZoomInMapIcon style={{ marginRight: 8 }} />
          Go to image
        </MenuItem>
        <MenuItem onClick={() => handleDownload(currentThumbId)}>
          <DownloadIcon style={{ marginRight: 8 }} />
          Download
        </MenuItem>
        <MenuItem onClick={() => handleDelete(currentThumbId)}>
          <DeleteIcon style={{ marginRight: 8 }} />
          Delete
        </MenuItem>
        <MenuItem onClick={() => handleStreetView(JSON.parse(contextMenuInfo.geom_4326))}>
          <MapIcon style={{ marginRight: 8 }} />
          Open in Street View
        </MenuItem>
        <Divider />
        <Typography variant="subtitle2" style={{ marginLeft: 16, marginRight: 16, marginTop: 8 }}>
          Username: {contextMenuInfo.username}
        </Typography>
        <Typography variant="subtitle2" style={{ marginLeft: 16, marginRight: 16 }}>
          Time Taken: {formatDate(contextMenuInfo.time_taken)}
        </Typography>
      </Menu>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected images?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ImageModal open={openImageModal} onClose={() => setOpenImageModal(false)} imageId={modalImageId} />
    </>
  );
}

export default Thumbnails;
