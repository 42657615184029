import React, { useState } from 'react';
import axios from 'axios';
import { TextField, List, ListItem, IconButton, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function AddressInput({ onAddressSelect }) {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showInput, setShowInput] = useState(false);

    const fetchSuggestions = async (query) => {
        if (query.length < 3) {
            setSuggestions([]);
            return;
        }

        const response = await axios.get('https://nominatim.openstreetmap.org/search', {
            params: {
                q: query,
                format: 'json',
                addressdetails: 1,
                limit: 5,
            },
        });

        setSuggestions(response.data);
    };

    const handleInputChange = (e) => {
        const newQuery = e.target.value;
        setQuery(newQuery);
        fetchSuggestions(newQuery);
    };

    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion.display_name);
        setSuggestions([]);
        onAddressSelect(suggestion);
        setShowInput(false); // Hide input after selection
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && suggestions.length > 0) {
            handleSuggestionClick(suggestions[0]);
        }
    };

    const toggleInput = () => {
        setShowInput(!showInput);
    };

    return (
        <div style={{ position: 'relative', width: 'fit-content', float: 'right' }}>
            <IconButton onClick={toggleInput} style={{ backgroundColor: 'white' }}>
                <SearchIcon />
            </IconButton>
            {showInput && (
                <Paper style={{ position: 'absolute', top: 0, right: '100%', zIndex: 1, marginRight: '8px', padding: '8px', display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        fullWidth
                        label="Search Address"
                        value={query}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        variant="outlined"
                        autoFocus
                        style={{ marginBottom: '8px', width: '500px' }}
                    />
                    {suggestions.length > 0 && (
                        <List style={{ maxHeight: '150px', overflowY: 'auto' }}>
                            {suggestions.map((suggestion) => (
                                <ListItem
                                    button
                                    key={suggestion.place_id}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion.display_name}
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Paper>
            )}
        </div>
    );
}

export default AddressInput;
