import React, { createContext, useRef } from 'react';

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
    const mapRef = useRef(null);

    return (
        <MapContext.Provider
            value={{
                mapRef
            }}
        >
            {children}
        </MapContext.Provider>
    );
};