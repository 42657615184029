import React, { createContext, useState, useCallback } from 'react';
import { useSearchService } from '../services/searchService';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { baseurl } from '../config';

export const ImagesContext = createContext();

export const ImagesProvider = ({ children }) => {
    const [images, setImages] = useState([]);
    const [imagesLoading, setImagesLoading] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [searchMode, setSearchMode] = useState(false);
    const [searchParams, setSearchParams] = useState({
        startDate: '',
        endDate: '',
        username: '',
        area: '',
        boundingBox: null,
    });

    const searchService = useSearchService({
        setImages,
        setSelectedIds,
        setNextPageUrl,
        setSearchParams,
        searchParams,
        nextPageUrl,
    });

    const searchImagesLoading = async () => {
        setImagesLoading(true);
        await searchService.searchImages();
        setImagesLoading(false);
    }

    const clearSearch = useCallback(() => {
        setImages([]);
        setNextPageUrl(null);
        setSearchParams(prev => ({
            startDate: '',
            endDate: '',
            username: '',
            area: '',
            boundingBox: searchMode ? prev.boundingBox : null,
        }));
    }, [searchMode]);

    const clearImages = useCallback(() => {
        setImages([]);
        setNextPageUrl(null);
    }, []);

    const downloadImages = async (ids) => {
        const fetchImage = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return blob;
        };

        if (ids.length === 1) {
            const id = ids[0];
            const image = images.find((img) => img.id === id);
            if (image) {
                const blob = await fetchImage(baseurl + image.local_path);
                const link = document.createElement('a');
                const blobUrl = URL.createObjectURL(blob);
                link.href = blobUrl;
                link.setAttribute('download', `enomix_fwa_image_${id}.jpg`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            }
        } else {
            const zip = new JSZip();
            for (const id of ids) {
                const image = images.find((img) => img.id === id);
                if (image) {
                    const blob = await fetchImage(baseurl + image.local_path);
                    zip.file(`enomix_fwa_image_${id}.jpg`, blob);
                }
            }

            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, 'images.zip');
            });
        }
    };

    return (
        <ImagesContext.Provider
            value={{
                images,
                imagesLoading,
                searchImages: searchImagesLoading,
                loadMoreImages: searchService.loadMoreImages,
                clearSearch,
                clearImages,
                searchMode,
                setSearchMode,
                searchParams,
                setSearchParams,
                nextPageUrl,
                downloadImages,
                deleteImages: searchService.deleteImages,
                selectedIds,
                setSelectedIds
            }}
        >
            {children}
        </ImagesContext.Provider>
    );
};
