import axios from 'axios';
import { generateNextUrl } from '../utilities/searchUtils';
import { baseurl } from '../config';

export const useSearchService = ({ setImages, setSelectedIds, setNextPageUrl, setSearchParams, searchParams, nextPageUrl }) => {
    const searchImages = async () => {
        console.log("=====searchImages======")
        try {
            let finalParams = { ...searchParams };
            if (searchParams.boundingBox) {
                const { bottomLeft, topRight } = searchParams.boundingBox;
                finalParams = {
                    ...searchParams,
                    min_x: bottomLeft.lon,
                    min_y: bottomLeft.lat,
                    max_x: topRight.lon,
                    max_y: topRight.lat,
                };
                delete finalParams.boundingBox;
            }
            const url = generateNextUrl(baseurl + './api/search-images/', finalParams);
            const response = await axios.get(url);
            setImages(response.data.results);
            setNextPageUrl(response.data.next);
        } catch (error) {
            console.error('Error searching images:', error);
        }
    };

    const loadMoreImages = async (forcedUrl) => {
        console.log("=====loadMoreImages======")
        try {
            let params = { ...searchParams };
            if (params.boundingBox) {
                const { bottomLeft, topRight } = params.boundingBox;
                params = {
                    ...params,
                    min_x: bottomLeft.lon,
                    min_y: bottomLeft.lat,
                    max_x: topRight.lon,
                    max_y: topRight.lat,
                };
                delete params.boundingBox;
            }
            const url = forcedUrl ? forcedUrl : nextPageUrl;
            console.log(url)
            if (url) {
                const response = await axios.get(url);
                setImages((prevImages) => [...prevImages, ...response.data.results]);
                setNextPageUrl(response.data.next);
                return { nexturl: response.data.next, ids: response.data.results.map(r => r.id) };
            } else {
                setNextPageUrl(null);
                return { nexturl: null, ids: [] };
            }
        } catch (error) {
            console.error('Error loading more images:', error);
        }
    };

    const deleteImages = async (ids) => {
        try {
            // Loop through each id and make a DELETE request to the API endpoint
            for (const id of ids) {
                await axios.delete(`./api/images/delete/${id}/`);
            }

            // Update the local state to remove the deleted images
            setImages((prevImages) => prevImages.filter((img) => !ids.includes(img.id)));
            setSelectedIds((prevIds) => prevIds.filter(id => !ids.includes(id)))

            console.log('Images marked as deleted successfully.');
        } catch (error) {
            console.error('Error deleting images:', error);
        }
    };

    return {
        searchImages,
        loadMoreImages,
        deleteImages,
    };
};
