import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import 'ol/ol.css';
import { Map as OLMap, View } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer, VectorTile as VectorTileLayer } from 'ol/layer';
import { OSM, Vector as VectorSource, VectorTile as VectorTileSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Style, Circle as CircleStyle, Fill, Stroke } from 'ol/style';
import { useGeolocated } from 'react-geolocated';
import { MVT } from 'ol/format';
import Overlay from 'ol/Overlay';
import { Box, IconButton } from '@mui/material';
import { ImagesContext } from '../contexts/ImagesContext';
import { MapContext } from '../contexts/MapContext';
import axios from 'axios';
import { baseurl } from '../config';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ImageModal from '../components/ImageModal';

function Map() {
  const { mapRef } = useContext(MapContext);
  const vectorLayerRef = useRef(null);
  const vectorTileLayerRef = useRef(null);
  const selectedFeaturesLayerRef = useRef(null);
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });
  const { images, setSelectedIds } = useContext(ImagesContext);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [modalImageId, setModalImageId] = useState('');

  const popupContainer = useRef();
  const popupContent = useRef();
  const popupOverlay = useRef();
  const closer = useRef();

  const onSingleClick = useCallback(async (evt) => {
    const feature = mapRef.current.forEachFeatureAtPixel(evt.pixel, function (feat) {
      return feat;
    });

    if (feature) {
      if (evt.originalEvent.ctrlKey) {
        const featureId = feature.getProperties().id;
        if (images.some(i => i.id === featureId)) {
          setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(featureId)) {
              return prevSelectedIds.filter((id) => id !== featureId);
            } else {
              return [...prevSelectedIds, featureId];
            }
          });
        }
      } else {
        const featureId = feature.getProperties().id;
        let image = null;
        let imageGetError = '';
        if (images.some(i => i.id === featureId)) {
          image = images.find(item => item.id === featureId);
        } else {
          try {
            const response = await axios.get(baseurl + `./api/image/${featureId}/`);
            if (response.status === 200) {
              image = response.data;
            } else {
              imageGetError = 'Image fetch failed';
            }
          } catch (error) {
            console.log(error);
            imageGetError = 'Request failed. Reason:' + error;
          }
        }

        if (image) {
          createPopup(evt.coordinate, image);
        } else if (imageGetError) {
          console.error(imageGetError);
        }
      }
    }
  }, [images, setSelectedIds]);

  const createPopup = (coordinate, image) => {

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    };

    const popupContentElement = popupContent.current;
    popupContentElement.innerHTML = ''; // Clear any existing content

    const img = document.createElement('img');
    img.src = `data:image/jpeg;base64,${image.thumbnail}`;
    img.style.cursor = 'pointer'; // Change cursor to indicate clickability
    img.ondblclick = () => {
      setModalImageId(image.id);
      setOpenImageModal(true);
    };
    popupContentElement.appendChild(img);

    const createTextElement = (label, text) => {
      const container = document.createElement('div');
      const labelElement = document.createElement('span');
      labelElement.style.fontWeight = 'bold';
      labelElement.textContent = label;
      const textElement = document.createElement('span');
      textElement.textContent = text;
      container.appendChild(labelElement);
      container.appendChild(document.createTextNode(': ')); // Add a colon and space between the label and text
      container.appendChild(textElement);
      container.style.fontSize = 'small'; // Make the text smaller
      return container;
    };

    // Create and append the username element
    const usernameElement = createTextElement('Username', image.username);
    popupContentElement.appendChild(usernameElement);

    const hrElement = document.createElement('hr');
    popupContentElement.appendChild(hrElement);

    // Create and append the time taken element
    const timeTakenElement = createTextElement('Time taken', formatDate(image.time_taken));
    popupContentElement.appendChild(timeTakenElement);

    popupOverlay.current.setPosition(coordinate);
  };

  useEffect(() => {
    if (!mapRef.current) {
      const vectorTileLayer = new VectorTileLayer({
        source: new VectorTileSource({
          url: baseurl + 'tileserver/tile/images/{z}/{x}/{y}/',
          format: new MVT(),
        }),
        style: new Style({
          image: new CircleStyle({
            radius: 12,
            fill: new Fill({
              color: 'red',
            }),
            stroke: new Stroke({
              color: 'white',
              width: 1,
            }),
          }),
        }),
      });

      const selectedFeaturesSource = new VectorSource();
      const selectedFeaturesLayer = new VectorLayer({
        source: selectedFeaturesSource,
        style: new Style({
          image: new CircleStyle({
            radius: 12,
            fill: new Fill({
              color: 'yellow',
            }),
            stroke: new Stroke({
              color: 'white',
              width: 1,
            }),
          }),
        }),
      });

      const olMap = new OLMap({
        target: 'map',
        controls: [],
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          vectorTileLayer,
          selectedFeaturesLayer,
        ],
        view: new View({
          center: fromLonLat([0, 0]),
          zoom: 2,
        }),
      });

      const vectorSource = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      olMap.addLayer(vectorLayer);
      mapRef.current = olMap;
      vectorLayerRef.current = vectorLayer;
      vectorTileLayerRef.current = vectorTileLayer;
      selectedFeaturesLayerRef.current = selectedFeaturesLayer;

      // Setup popup overlay
      popupContainer.current = document.getElementById('popup');
      popupContent.current = document.getElementById('popup-content');
      closer.current = document.getElementById('popup-closer');
      popupOverlay.current = new Overlay({
        element: popupContainer.current,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });

      mapRef.current.addOverlay(popupOverlay.current);

      closer.current.onclick = function () {
        popupOverlay.current.setPosition(undefined);
        closer.current.blur();
        return false;
      };

      olMap.on('singleclick', onSingleClick);
      //olMap.on('movestart', onMoveStart);
      //olMap.on('moveend', onMoveEnd);
    }
  }, [onSingleClick]);

  useEffect(() => {
    if (mapRef.current) {
      const olMap = mapRef.current;
      olMap.on('singleclick', onSingleClick);
      //olMap.on('movestart', onMoveStart);
      //olMap.on('moveend', onMoveEnd);

      return () => {
        olMap.un('singleclick', onSingleClick);
        //olMap.un('movestart', onMoveStart);
        //olMap.un('moveend', onMoveEnd);
      };
    }
  }, [onSingleClick]);

  useEffect(() => {
    if (mapRef.current) {
      if (coords && mapRef.current && vectorLayerRef.current) {
        const { latitude, longitude } = coords;

        mapRef.current.getView().setCenter(fromLonLat([longitude, latitude]));
        mapRef.current.getView().setZoom(16);

        // Force map render refresh
        mapRef.current.render();
      }
    }
  }, [coords]);


  const handleZoomIn = () => {
    const view = mapRef.current.getView();
    view.setZoom(view.getZoom() + 1);
  };

  const handleZoomOut = () => {
    const view = mapRef.current.getView();
    view.setZoom(view.getZoom() - 1);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <div id="map" style={{ width: '100%', height: '100%' }}></div>
      <div id="popup" className="ol-popup">
        <a href="#" id="popup-closer" className="ol-popup-closer"></a>
        <div id="popup-content"></div>
      </div>
      <Box
        sx={{
          position: 'absolute',
          top: 16,
          left: 16,
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton onClick={handleZoomIn} style={{ backgroundColor: 'white', marginBottom: 5 }} color="primary">
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={handleZoomOut} style={{ backgroundColor: 'white' }} color="primary">
          <ZoomOutIcon />
        </IconButton>
      </Box>
      <ImageModal open={openImageModal} onClose={() => setOpenImageModal(false)} imageId={modalImageId} />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#f0f0f0', // You can adjust this background color as needed
  },
  message: {
    fontSize: '24px',
    color: '#333',
  },
};

export default Map;
